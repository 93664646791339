import React from 'react';

import { WLText } from '../../design-library';
import PageContext from '../../templates/context/PageContext';

interface PageIdentifierProps {
  webNumber: string | undefined;
  secondaryWebNumber?: string | undefined;
  lastUpdated: string | undefined;
}

const PageIdentifier: React.FC<PageIdentifierProps> = ({
  webNumber,
  secondaryWebNumber,
  lastUpdated,
}) => {
  const path = React.useContext(PageContext);

  if (!webNumber && !lastUpdated) {
    return null;
  }

  const aDate = lastUpdated?.split('/');
  let date = lastUpdated;

  if (aDate?.length === 3) {
    const tempYear = aDate[2].toString();
    let year = new Date().getFullYear().toString();

    if (tempYear.length === 4) {
      year = tempYear.substring(2);
    } else {
      year = tempYear;
    }

    date = `${aDate[0]}/${year}`;
  }

  return (
    <WLText
      mb={{ base: '60px', lg: '160px' }} // TODO: We need to figure out why this value isn't a multiplier of our base value (4px). -NE 20230530
      fontSize={12} // TODO: We need to figure out why this value isn't a multiplier of our base value (4px). -NE 20230530
      fontWeight={700}
      color="gray.600"
    >
      {/^\/landing\/always-on\//.test(path || '') && (
        <>
          Woodmen of the World Life Insurance Society, Omaha, NE
          <br />
        </>
      )}
      {[webNumber, date].filter((val) => !!val).join(' R-')}
      <br />
      <br />
      <br />
      <br />
      {secondaryWebNumber}
    </WLText>
  );
};

export default PageIdentifier;
